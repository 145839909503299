<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('userInfo.default[0]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div class="Panel">
      <van-form>
      <van-cell
        size="large"
        :icon="`./static/icon/info_0001.png`"
        :title="$t('userInfo.default[1]')"
        center
        @click="showHeader = true"
      >
        <div class="userFace" slot="right-icon">
          <img :src="`./static/head/${UserInfo.header}`" />
        </div>
      </van-cell>
      <van-cell
              size="large"
              :icon="`./static/icon/info_0002.png`"
              :title="$t('userInfo.default[15]')"
              center
              :value="UserInfo.realname"
              :value-class="[UserInfo.realname ? 'isTrue' : '']"
      />
      <van-cell
              size="large"
              :icon="`./static/icon/info_0002.png`"
              :title="$t('userInfo.default[2]')"
              center
              :value="UserInfo.username ? UserInfo.username : $t('userInfo.default[8]')"
              :is-link="UserInfo.username ? false : true"
              :value-class="[UserInfo.username ? 'isTrue' : '']"
      />
      <van-cell
              size="large"
              :icon="`./static/icon/info_0002.png`"
              :title="$t('userInfo.default[3]')"
              center
              :value="UserInfo.username ? UserInfo.username : $t('userInfo.default[8]')"
              :is-link="UserInfo.username ? false : true"
              :value-class="[UserInfo.username ? 'isTrue' : '']"
      />
      <van-cell
              size="large"
              :icon="`./static/icon/info_0002.png`"
              :title="$t('userInfo.default[16]')"
              center
              :value="UserInfo.idcode"
              :value-class="['']"
      />
      <van-cell
              size="large"
              :icon="`./static/icon/info_0005.png`"
              :title="$t('userInfo.default[6]')"
              center
              :value="$t('userInfo.default[8]')"
              is-link
              @click="showPassWord = true"
      />
        <van-cell
            size="large"
            :icon="`./static/icon/info_0002.png`"
            :title="$t('userInfo.default[17]')"
            center
            :value="UserInfo.credit"
            :value-class="['']"
        />
      </van-form></div>
      <div style="text-align: center;margin-top: 20px">
        <van-button @click="setUserInfo">{{ $t('userInfo.label[6]') }}</van-button>
      </div>
    </div>
    <van-action-sheet
      v-model="showHeader"
      :title="$t('userInfo.default[9]')"
      close-on-popstate
    >
      <van-radio-group v-model="radioHeader">
        <van-grid clickable icon-size="45" column-num="5">
          <van-grid-item
            :icon="`./static/head/head_${item}.png`"
            v-for="item in 40"
            :key="item"
            @click="selectHeader(item)"
          />
        </van-grid>
      </van-radio-group>
    </van-action-sheet>
    <van-action-sheet
      v-model="showPassWord"
      :title="$t('userInfo.default[10]')"
      close-on-popstate
    >
      <van-field
        v-model.trim="postData.o_password"
        type="password"
        :label="$t('userInfo.label[0]')"
        :placeholder="$t('userInfo.placeholder[0]')"
        clearable
        size="large"
      />
      <van-field
        v-model.trim="postData.n_password"
        type="password"
        :label="$t('userInfo.label[1]')"
        :placeholder="$t('userInfo.placeholder[1]')"
        clearable
        size="large"
      />
      <van-field
        v-model.trim="postData.r_password"
        type="password"
        :label="$t('userInfo.label[2]')"
        :placeholder="$t('userInfo.placeholder[2]')"
        clearable
        size="large"
      />
      <div style="padding: 16px">
          <van-button @click="setpwd">{{ $t("userInfo.default[12]") }}</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  name: "Info",
  components: {},
  props: [],
  data() {
    return {
      showHeader: false,
      showPassWord: false,
      showPayWord: false,
      radioHeader: "",
      postData: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.postData.header = this.UserInfo.header;
    this.$Model.GetUserInfo();
    console.log(this.UserInfo);
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    selectHeader(index) {
      this.postData.header = `head_${index}.png`;
      this.UserInfo.header  = `head_${index}.png`;
      if (this.showHeader) {
        this.showHeader = false;
      }
    },
    setpwd(){
      if (this.showPassWord) {
        this.showPassWord = false;
      }
    },
    setUserInfo() {
      debugger
      this.$Model.SetUserInfo(this.postData, (data) => {
        if (data.code == 1) {
          if (this.showHeader) {
            this.showHeader = false;
          }
          if (this.showPassWord) {
            this.showPassWord = false;
          }
          if (this.showPayWord) {
            this.showPayWord = false;
          }
          this.postData = {};
        }
      });
    },
    clearCache() {
      // localStorage.clear();
      // this.$Model.GetBackData();
      // this.$router.push("/login");
      this.$Model.Logout()
    },
  },
};
</script>
<style scoped>
  .PageBox {

  }

  .van-icon__image {
    width: 100%;
    height: 100%;
  }

  .ScrollBox {
    padding: 0 0px;
  }

  .ScrollBox >>> .userFace {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    padding: 6px;
    overflow: hidden;
  }
  .ScrollBox >>> .userFace img {
    width: 100%;
    border-radius: 50%;
  }
</style>
